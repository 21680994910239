<template>
  <div class="privacy">
    <header class="privacy__header">
      <h1 class="privacy__title">Privacy Policy</h1>
    </header>
    <article class="privacy__body">
      <p class="privacy__effective-date">Effective Date: 18 May 2021</p>

      <p>
        I take a privacy-first approach to my app development, collecting the
        minimum amount of user data necessary to deliver the core functionality
        of the app. Happily, in the case of Shtum, that means zero data
        collection or tracking, either by me (the app developer) or any third
        party. So this may well be the shortest Privacy Policy you'll ever have
        the pleasure of reading. Hooray!
      </p>

      <p>
        If you have questions regarding this privacy policy, please email me at
        <a class="privacy__link" href="mailto:hello@shtum.app?subject=Privacy"
          >hello@shtum.app</a
        >.
      </p>

      <p>
        If I need to make any changes to this privacy policy, I will clearly
        indicate those changes on this page.
      </p>

      <p>--</p>

      <p>
        Jeremy Burton<br />
        Developer of Shtum
      </p>
    </article>
  </div>
</template>

<style>
.privacy {
}
.privacy__header {
  background: var(--brand-primary-50);
}
.privacy__title {
  max-width: 48rem;
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.privacy__body {
  max-width: 48rem;
  margin: 0 auto;
  padding: 1.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.privacy__effective-date {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.privacy__link {
  color: var(--brand-secondary-60);
}
</style>
